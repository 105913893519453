import React from "react";
import Layout from "../components/Layout";
import {Box} from "../styles/basics";

const NotFoundPage = () => (
  <Layout title="Choice - 404 page not found">
    <Box>
      <h1>404 - Seite nicht gefunden</h1>
    </Box>
  </Layout>
);

export default NotFoundPage;
